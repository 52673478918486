import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import APIService from "../services/APIService";
import CookieService from "../services/Cookie";

interface AuthContextUser {
  email: string;
  username: string;
}

type CallBack = ({
  email,
  username,
}: {
  email: string;
  username: string;
}) => void;

interface AuthContextType {
  user: AuthContextUser | null;
  signIn: (
    user: string,
    password: string,
    callback?: CallBack,
    errorCallback?: VoidFunction
  ) => void;
  signOut: () => void;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children?: React.ReactNode }) {
  const [user, setUser] = useLocalStorage("moderation_user", null);

  const signIn = (
    newUser: string,
    password: string,
    successCallback?: CallBack,
    errorCallback?: VoidFunction
  ) => {
    return APIService.authenticateUser(newUser, password)
      .then(({ token, email, username }) => {
        setUser({ email, username });
        CookieService.saveUserJWT(token);

        return {
          email,
          username,
        };
      })
      .then(successCallback)
      .catch(errorCallback);
  };

  const signOut = () => {
    setUser(null);
    CookieService.deleteUserJWT();
  };

  const value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
