import axios from "axios";
import ROUTES from "../pages/Router/Routes";
import Cookie from "../services/Cookie";

const DEV_URL = "";
const PROD_URL = "https://moderation-api.podchaser.com";
const BASE_URL = DEV_URL || PROD_URL;

const BASIC_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const instance = axios.create({
  baseURL: process.env.API_URL || BASE_URL,
  headers: BASIC_HEADERS,
});

/**
 * Add JWT Token for requests
 */
instance.interceptors.request.use(
  async (config) => {
    const jwtToken = Cookie.getUserJWT();

    if (jwtToken) {
      config.headers = {
        ...BASIC_HEADERS,
        "x-podchaser-internal-auth": jwtToken,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Catch the Unauthorized Request
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = ROUTES.LOGIN;
    }
  }
);

export default instance;
