import amplitude from "amplitude-js";

const AMPLITUDE_ID = "7eae59cfc1c5bad0dc610fb388f44e9f";

export const initAmplitude = () => {
  amplitude.getInstance().init(AMPLITUDE_ID);
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: {
  [key: string]: any;
}) => {
  amplitude.getInstance().setUserProperties(properties);
};

export enum AMPLITUDE_KEYS {
  LOGIN = "moderationLogin",
  LOGOUT = "moderationLogout",
  START_SEQUENCE = "moderationStartSequence",
  MODERATE_SEQUENCE = "moderationTaskDecision",
  SKIP = "moderationSkip",
}

export const sendAmplitudeData = (
  eventType: AMPLITUDE_KEYS,
  eventProperties?: any
) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== "test") {
    console.log("USER_ANALYTICS", eventType, eventProperties);
  }

  if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};
