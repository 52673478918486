import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import Router from "./pages/Router";
import reportWebVitals from "./reportWebVitals";
import { initAmplitude } from "./services/Amplitude";
import "./wdyr";

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  initAmplitude();
  Bugsnag.start({
    apiKey: "41ec6b49bfe495ab46a575a7ee7f0f34",
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

Bugsnag.notify(new Error("Test error"));

render(
  <React.StrictMode>
    {ErrorBoundary ? (
      <ErrorBoundary>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ErrorBoundary>
    ) : (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
