import { AxiosResponse } from "axios";
import queryString from "query-string";
import client from "../api/client";
import { TaskDecision } from "../context/TaskDecisionsContext";
import { Sequence } from "../interfaces/Sequence";
import {
  AuthenticateUserResponse,
  ListSequenceResponse,
  SequenceCountResponse,
} from "./APIServiceResponse";

const API_ROUTES = {
  AUTHENTICATION: "/login",
  SEQUENCES: "/sequences",
  GET_SEQUENCES_DECISIONS: (sequenceID: string) =>
    `/sequences/${sequenceID}/decisions`,
  SKIP_SEQUENCE: (sequenceID: string) => `/sequences/${sequenceID}/skip`,
  SEQUENCES_COUNTS: "/sequences/counts",
  UNLOCK_SEQUENCES: "/sequences/unlock",
};

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string) => client.get(url).then(responseBody),
  post: (url: string, body?: {}) => client.post(url, body).then(responseBody),
  put: (url: string, body?: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

const APIService = {
  authenticateUser: (
    username: string,
    password: string
  ): Promise<AuthenticateUserResponse> =>
    requests.post(
      API_ROUTES.AUTHENTICATION,
      JSON.stringify({ username, password })
    ),
  getSequence: (
    includeLockedSequence?: boolean,
    limit?: number
  ): Promise<Sequence[]> => {
    let url = API_ROUTES.SEQUENCES;

    if (includeLockedSequence !== undefined || limit !== undefined) {
      const query = queryString.stringify(
        {
          ...(includeLockedSequence
            ? { include_locked_by_user: includeLockedSequence }
            : {}),
          ...(limit ? { limit } : {}),
          limit,
        },
        { arrayFormat: "bracket" }
      );

      url = `${API_ROUTES.SEQUENCES}?${query}`;
    }

    return requests.get(url).then((response: ListSequenceResponse) => {
      if (response?.result) {
        return response.result.map((result: Sequence) => {
          return {
            ...result,
            id: result?._id,
          };
        });
      }

      return [];
    });
  },
  // getSequence: (
  //   includeLockedSequence?: boolean,
  //   limit?: number
  // ): Promise<Sequence[]> =>
  //   new Promise(function (resolve, reject) {
  //     resolve([
  //       {
  //         id: "63bcdb22461c0c4bc100a99a",
  //         _id: "63bcdb22461c0c4bc100a99a",
  //         type: "credit_submission_ongoing",
  //         can_be_moderated_by: ["admin", "moderator"],
  //         status: "incomplete",
  //         podcast: {
  //           slug: "The Witch Farm",
  //           id: 682104,
  //           title: "The Jolly Swagman Podcast",
  //           description:
  //             "The JSP is a weekly exploration of questions that are both important and overlooked. Joe Walker, a former political speechwriter helping to build a Y Combinator-backed startup, hosts refreshingly in-depth conversations. Topics cover capitalism, science, business, culture and politics. Guests include Nobel laureates, former prime ministers, authors, entrepreneurs, investors and thought-leaders. Australia's best long-form podcast. A space for curious contrarians.",
  //           image_url:
  //             "https://www.omnycontent.com/d/playlist/2fb3740d-3436-44af-8cc0-a91900716aa5/974da661-6c3e-403b-ae4c-ad830015ac47/6a46e27c-2d68-48c4-940d-ad830015acc6/image.jpg?t=1628817536&size=Large",
  //           web_url: "http://www.josephnoelwalker.com",
  //           author: "josephnoelwalker@gmail.com",
  //           url: "https://www.podchaser.com/podcasts/the-jolly-swagman-podcast-682104",
  //         },
  //         creator: {
  //           id: 39180,
  //           name: "Timur Kuran",
  //           bio: "Timur Kuran is a economist, writer, researcher, professor of Economics & Political Science, and Gorter Family Professor in Islamic Studies at Duke University.",
  //           profile_image_url:
  //             "https://creator-images.podchaser.com/76da1098a117e6e01655b1960942a586.jpeg",
  //           twitter_handle: "timurkuran",
  //           pronouns: "he_him",
  //           informal_name: "Timur",
  //           wiki_handle: "Timur Kuran",
  //           date_of_birth: null,
  //           location:
  //             "City of Durham, North Carolina, United States of America",
  //           type: "creator",
  //           pending_fields: {},
  //           pending_fields_status: {},
  //         },
  //         role: {
  //           code: "themeMusic",
  //           title: "Theme Music",
  //           description:
  //             "Theme Music is a musical piece that accompanies the podcast across multiple episodes, most often at the beginning of an episode. The Theme Music is used to introduce the podcast as a brand. This role is for the creator of the theme music.",
  //         },
  //         submittingUser: {
  //           id: 15471,
  //           username: "mistakesweremade",
  //           profile_image_url:
  //             "https://user-images.podchaser.com/b2104912e736de992ae3a9a088da7aee2aeb1a8f8475cef63900be5d0780e872.png",
  //           type: "submitting_user",
  //           join_date: "2019-03-13T00:30:50.000000Z",
  //         },
  //         creditSubmissionOngoing: {
  //           id: 32345,
  //           creator_id: 135342,
  //           podcast_id: 4924110,
  //           role_code: "themeMusic",
  //           ongoing: 1,
  //           user_id: null,
  //         },
  //         tasks: [
  //           {
  //             type: TASK_TYPE.ADD_EPISODE_CREDITS,
  //             decision_status: TASK_DECISION_STATUS.WAITING_FOR_CONSENSUS,
  //             task_info: {
  //               podcast_id: 4924110,
  //               creator_id: 135342,
  //               role_code: "themeMusic",
  //               ongoing: 1,
  //             },
  //             required_for_consensus: 1,
  //             id: "63bcdb22461c0c4bc100a999",
  //           },
  //         ],
  //         episodes: [
  //           {
  //             id: 39658354,
  //             title:
  //               "Housing Bubble Week: Housing Bubbles as Availability Cascades -- Timur Kuran",
  //             air_date: "2019-05-15 13:07:37",
  //             length: 2679,
  //             image_url:
  //               "http://static1.squarespace.com/static/5881eb75db29d6a92daa3e19/590fdd97893fc0f07b7a48c9/5cdc0a45f4e1fc2287ec82ca/1557925658680/1500w/Timur-Kuran-headshot.jpg",
  //             audio_url:
  //               "https://media.blubrry.com/thejollyswagmen/content.blubrry.com/thejollyswagmen/Jolly_Swagman-Bubble_Week-Timur_Kuran_R.mp3",
  //             description:
  //               "Timur Kuran is a Turkish American economist, Professor of Economics and Political Science, and Gorter Family Professor in Islamic Studies at Duke University.In 2017, Timur Kuran and legal scholar Cass Sunstein advanced an incredibly powerful construct called an availability cascade. Availability cascades combine informational cascades and reputational cascades, and are mediated by the availability heuristic (all will be explained in the podcast).Joe and Timur talk about the application of availability cascades to housing bubbles, and the \u2018availability entrepreneurs\u2019 who populate housing bubbles.Show notes:Selected links- Follow Timur: Website | Twitter- \u2018On the Likely Extent of Falls in Irish House Prices\u2019, paper by Morgan Kelly- \u2018A Theory of Fads, Fashion, Custom, and Cultural Change as Informational Cascades\u2019, paper by Sushil Bikhchandani et al- \u2018How a Bubble Stayed Under the Radar\u2019, The New York Times article by Robert Shiller- Irrational Exuberance, by Robert Shiller- \u2018Availability Cascades and Risk Regulation\u2019, paper by Timur Kuran and Cass Sunstein- \u2018Availability: A Heuristic for Judging Frequency and Probability\u2019, paper by Amos Tversky and Daniel Kahneman- \u2018A History of Housing Prices in Australia 1880-2010\u2019, paper by Nigel StapledonTopics discussed- How do availability cascades apply to economic bubbles? [11:59]- The availability entrepreneurs of the property market. [19:44]- Availability cascades versus the efficient markets hypothesis. [33:47]",
  //             url: "https://www.podchaser.com/podcasts/the-jolly-swagman-podcast-682104/episodes/housing-bubble-week-housing-bu-39658354",
  //           },
  //         ],
  //         locked_at: "2023-01-10T22:53:08.841000Z",
  //         locked_by: 120598,
  //         updated_at: "2023-01-10T22:53:08.841000Z",
  //         created_at: "2023-01-10T03:27:30.517000Z",
  //       },
  //     ]);
  //   }),
  submitTasks: (
    sequenceId: string,
    taskDecision: TaskDecision[]
  ): Promise<{}> =>
    requests.post(API_ROUTES.GET_SEQUENCES_DECISIONS(sequenceId), {
      decisions: taskDecision,
    }),
  skipSequence: (sequenceId: string): Promise<{}> =>
    requests.post(API_ROUTES.SKIP_SEQUENCE(sequenceId), {}),
  getSequenceCount: (): Promise<SequenceCountResponse> =>
    requests.get(API_ROUTES.SEQUENCES_COUNTS),
  unlockSequences: (sequences: string[]): Promise<{}> =>
    requests.post(API_ROUTES.UNLOCK_SEQUENCES, {
      sequences,
    }),
};

export default APIService;
