import Cookies from "universal-cookie";

const LOCAL_STORAGE_JWT_KEY = "moderation_token";

class CookieService {
  static cookies = new Cookies();

  static saveUserJWT(jwt: string): void {
    this.cookies.set(LOCAL_STORAGE_JWT_KEY, jwt, {
      path: "/",
    });
  }
  static getUserJWT(): string {
    return this.cookies.get(LOCAL_STORAGE_JWT_KEY);
  }
  static deleteUserJWT(): void {
    this.cookies.remove(LOCAL_STORAGE_JWT_KEY);
  }
}

export default CookieService;
