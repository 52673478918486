import { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AuthProvider from "../../context/AuthContext";
import SequenceProvider from "../../context/SequenceContext";
import TaskDecisionProvider from "../../context/TaskDecisionsContext";
import RequireAuth from "../RequireAuth";
import ROUTES from "./Routes";

const Home = lazy(() => import("../Home"));
const Login = lazy(() => import("../Login"));
const NoMatch = lazy(() => import("../NoMatch"));
const Layout = lazy(() => import("../Layout"));
const SequenceLayoutContainer = lazy(
  () => import("../Layout/SequenceLayoutContainer")
);
const Sequence = lazy(() => import("../Sequence"));

export default function Router() {
  const [isAFK, setIsAFK] = useState(false);

  return (
    <Suspense fallback={<div></div>}>
      <AuthProvider>
        <SequenceProvider>
          <TaskDecisionProvider>
            <Routes>
              <Route path={ROUTES.HOME} element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route index element={<Home />} />
                </Route>
                <Route
                  element={
                    <SequenceLayoutContainer showSequenceCount={!isAFK} />
                  }
                >
                  <Route
                    path={ROUTES.SEQUENCE}
                    element={<Sequence setIsAFK={setIsAFK} isAFK={isAFK} />}
                  />
                </Route>
              </Route>
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </TaskDecisionProvider>
        </SequenceProvider>
      </AuthProvider>
    </Suspense>
  );
}
