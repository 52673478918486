import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Sequence, Task, TASK_DECISION_STATUS } from "../interfaces/Sequence";
import APIService from "../services/APIService";

type SequenceList = { [n: string]: Sequence };

interface SequenceContextType {
  addSequence: (sequence: Sequence) => void;
  clearData: () => void;
  currentSequence: string | null;
  deleteSequence: (
    sequenceId: string,
    callback?: ({ sequencesSize }: { sequencesSize: number }) => void
  ) => void;
  fetchNewSequences: (
    callback: (response: Sequence[]) => void,
    errorCallback: VoidFunction,
    includeLockedSequence?: boolean,
    limit?: number
  ) => void;
  getAllSequences: () => Sequence[];
  getSequence: (sequenceId: string) => Sequence | undefined;
  getSequenceSize: () => number;
  hasMoreTasks: (taskId: string | null) => boolean;
  hasNextSequence: (excludedSequenceId?: string) => boolean;
  moveToNextSequence: (currentSequence?: string) => void;
  moveToNextTask: () => void;
  setCurrentSequence: Dispatch<SetStateAction<string | null>>;
  setCurrentTaskProcessing: (task: string | null) => void;
  setTaskId: Dispatch<SetStateAction<string | null>>;
  taskId: string | null;
}

export const SequenceContext = React.createContext<SequenceContextType | null>({
  addSequence: () => {},
  clearData: () => undefined,
  currentSequence: null,
  deleteSequence: () => undefined,
  fetchNewSequences: () => {},
  getAllSequences: () => [],
  getSequence: () => undefined,
  getSequenceSize: () => 0,
  hasMoreTasks: () => false,
  hasNextSequence: () => false,
  moveToNextSequence: () => undefined,
  moveToNextTask: () => undefined,
  setCurrentSequence: () => {},
  setCurrentTaskProcessing: () => undefined,
  setTaskId: () => undefined,
  taskId: null,
});

function SequenceProvider({ children }: { children?: React.ReactNode }) {
  const [sequences, setSequences] = useState<SequenceList>({});
  const [currentSequence, setCurrentSequence] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);

  const sequenceKeys = Object.keys(sequences);

  const addSequence = useCallback((newSequence: Sequence) => {
    setSequences((oldState) => {
      if (!newSequence || !newSequence.id) {
        return oldState;
      }

      return {
        ...oldState,
        [newSequence.id]: {
          ...(oldState[newSequence?.id] ? oldState[newSequence.id] : {}),
          ...newSequence,
        },
      };
    });
  }, []);

  const clearData = useCallback(() => {
    setSequences({});
    setCurrentSequence(null);
    setTaskId(null);
  }, []);

  const getFirstUnModeratedTas = (tasks: Task[] = []) => {
    return tasks.find((task) => {
      return (
        task.decision_status === TASK_DECISION_STATUS.WAITING_FOR_CONSENSUS
      );
    });
  };

  const fetchNewSequences = useCallback(
    (
      successCallback: (response: Sequence[]) => void,
      errorCallback: VoidFunction,
      includeLockedSequence?: boolean,
      limit?: number
    ) => {
      return APIService.getSequence(includeLockedSequence, limit)
        .then((response: Sequence[]) => {
          let isSettledTaskAndSequence = false;

          for (let sequence of response) {
            const taskToBeModerated = getFirstUnModeratedTas(sequence.tasks);
            const alreadyExistSequence = sequenceKeys.includes(sequence.id);

            if (taskToBeModerated && !alreadyExistSequence) {
              addSequence(sequence);

              if (!isSettledTaskAndSequence && currentSequence === null) {
                setTaskId(taskToBeModerated.id);
                setCurrentSequence(sequence.id);
                isSettledTaskAndSequence = true;
              }
            }
          }

          return response;
        })
        .then(successCallback)
        .catch(errorCallback);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addSequence, JSON.stringify(sequenceKeys), currentSequence]
  );

  const getSequence = useCallback(
    (sequenceId: string): Sequence | undefined => {
      return sequences[sequenceId];
    },
    [sequences]
  );

  const getAllSequences = useCallback((): Sequence[] => {
    return Object.keys(sequences).reduce((acc: Sequence[], sequenceId) => {
      acc.push(sequences[sequenceId]);

      return acc;
    }, []);
  }, [sequences]);

  const getSequenceSize = useCallback((): number => {
    return Object.keys(sequences).length;
  }, [sequences]);

  const moveToNextTask = useCallback(() => {
    if (currentSequence) {
      const currentSequenceEntity = getSequence(currentSequence);

      if (currentSequenceEntity) {
        const taskToBeModerated = getFirstUnModeratedTas(
          currentSequenceEntity.tasks
        );

        if (taskToBeModerated) {
          setTaskId(taskToBeModerated.id);
        }
      }
    }
  }, [currentSequence, getSequence]);

  const moveToNextSequenceCallBack = useCallback(
    (sequences: SequenceList, currentSequence?: string) => {
      const sequenceKeys = Object.keys(sequences);

      for (const sequenceKey of sequenceKeys) {
        const sequence = sequences[sequenceKey];

        if (currentSequence === sequence.id) {
          continue;
        }

        const taskToBeModerated = getFirstUnModeratedTas(sequence.tasks);

        if (taskToBeModerated) {
          setTaskId(taskToBeModerated.id);
          setCurrentSequence(sequenceKey);
          break;
        }
      }
    },
    []
  );

  const moveToNextSequence = useCallback(
    (currentSequence?: string) => {
      moveToNextSequenceCallBack(sequences, currentSequence);
    },
    [sequences, moveToNextSequenceCallBack]
  );

  const deleteSequence = useCallback(
    (sequenceId: string, callBack) => {
      setSequences((oldState) => {
        if (!sequenceId) {
          return oldState;
        }

        delete oldState[sequenceId];

        if (callBack) {
          callBack({ sequencesSize: Object.keys(oldState).length });
        }

        if (sequenceId === currentSequence) {
          moveToNextSequenceCallBack(oldState, sequenceId);
        }

        return oldState;
      });
    },
    [currentSequence, moveToNextSequenceCallBack]
  );

  const hasMoreTasks = useCallback(
    (taskId: string | null) => {
      if (currentSequence) {
        const currentSequenceEntity = getSequence(currentSequence);

        if (currentSequenceEntity) {
          let listTasks = currentSequenceEntity.tasks;

          if (taskId) {
            listTasks = currentSequenceEntity.tasks.filter((task) => {
              return task.id !== taskId;
            });
          }

          const taskToBeModerated = getFirstUnModeratedTas(listTasks);

          return !!taskToBeModerated;
        }
      }

      return false;
    },
    [currentSequence, getSequence]
  );

  const hasNextSequence = useCallback(
    (excludedSequenceId?: string) => {
      const sequenceKeys = Object.keys(sequences);

      for (const sequenceKey of sequenceKeys) {
        const sequence = sequences[sequenceKey];

        const taskToBeModerated = getFirstUnModeratedTas(sequence.tasks);

        if (taskToBeModerated && excludedSequenceId !== sequence.id) {
          return true;
        }
      }

      return false;
    },
    [sequences]
  );

  const setCurrentTaskProcessing = useCallback(
    (taskId: string | null) => {
      if (currentSequence && taskId) {
        setSequences((oldState) => {
          const updatedTasks = oldState[currentSequence].tasks.map(
            (task: Task) => {
              if (task.id !== taskId) {
                return task;
              }

              return {
                ...task,
                decision_status: TASK_DECISION_STATUS.PROCESSING,
              };
            }
          );

          return {
            ...oldState,
            [currentSequence]: {
              ...oldState[currentSequence],
              tasks: updatedTasks,
            },
          };
        });
      }
    },
    [currentSequence]
  );

  const value = {
    addSequence,
    clearData,
    currentSequence,
    deleteSequence,
    fetchNewSequences,
    getAllSequences,
    getSequence,
    getSequenceSize,
    hasMoreTasks,
    hasNextSequence,
    moveToNextSequence,
    moveToNextTask,
    setCurrentSequence,
    setCurrentTaskProcessing,
    setTaskId,
    taskId,
  };

  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  );
}

export default SequenceProvider;
