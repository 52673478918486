import React, { createContext, ReactNode, useCallback, useState } from "react";

export enum TASK_DECISION_ACTION {
  APPROVE = "approve",
  REJECT = "reject",
}

export type TaskDecision = {
  task_id: string;
  action: TASK_DECISION_ACTION;
};

interface TaskDecisionContextType {
  addTaskDecision: (
    taskId: string | null,
    taskDecision: TASK_DECISION_ACTION
  ) => TaskDecision[];
  clearTaskDecisions: () => void;
  clearTask: (taskId: string) => void;
  getTaskDecisions: () => TaskDecision[];
  registerNewTaskDecision: (
    taskId: string | null,
    taskDecision: TASK_DECISION_ACTION
  ) => TaskDecision[];
}

export const TaskDecisionContext =
  createContext<TaskDecisionContextType | null>({
    addTaskDecision: () => [],
    clearTaskDecisions: () => undefined,
    clearTask: () => undefined,
    getTaskDecisions: () => [],
    registerNewTaskDecision: () => [],
  });

function TaskDecisionProvider({ children }: { children?: ReactNode }) {
  const [taskDecisions, setTaskDecisions] = useState<TaskDecision[]>([]);

  const clearTaskDecisions = useCallback(() => {
    setTaskDecisions([]);
  }, []);

  const clearTask = useCallback((taskId: string) => {
    setTaskDecisions((oldTasks) => {
      return oldTasks.filter((task) => task.task_id !== taskId);
    });
  }, []);

  const getTaskDecisions = useCallback(() => {
    return taskDecisions.concat();
  }, [taskDecisions]);

  const registerNewTaskDecision = useCallback(
    (taskId: string | null, taskDecision: TASK_DECISION_ACTION) => {
      if (taskId && taskDecision) {
        setTaskDecisions((oldTaskDecisions) => {
          const newTaskDecisions = oldTaskDecisions.concat({
            task_id: taskId,
            action: taskDecision,
          });
          return newTaskDecisions;
        });
      }

      return [];
    },
    []
  );

  const addTaskDecision = useCallback(
    (
      taskId: string | null,
      taskDecision: TASK_DECISION_ACTION
    ): TaskDecision[] => {
      if (taskId && taskDecision) {
        const newDecision = {
          task_id: taskId,
          action: taskDecision,
        };

        return taskDecisions.concat(newDecision);
      }

      return [];
    },
    [taskDecisions]
  );

  const value = {
    addTaskDecision,
    clearTaskDecisions,
    clearTask,
    getTaskDecisions,
    registerNewTaskDecision,
  };

  return (
    <TaskDecisionContext.Provider value={value}>
      {children}
    </TaskDecisionContext.Provider>
  );
}

export default TaskDecisionProvider;
