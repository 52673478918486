import { PRONOUNS_Keys } from "../utils/pronouns";

export const ONGOING_SEQUENCE_TYPES = {
  CREDIT_SUBMISSION_ON_GOING: "credit_submission_ongoing",
};

export const SequenceTypes = {
  ...ONGOING_SEQUENCE_TYPES,
  CREDIT_SUBMISSION: "credit_submission",
  CREATOR_SUBMISSION: "creator_submission",
};

const sequenceTypes = [
  SequenceTypes.CREDIT_SUBMISSION,
  SequenceTypes.CREATOR_SUBMISSION,
  ONGOING_SEQUENCE_TYPES.CREDIT_SUBMISSION_ON_GOING,
] as const;

type SequenceType = typeof sequenceTypes[number];

type SequenceStatus = "incomplete" | "complete";

export enum PENDING_FIELD_STATUS {
  PENDING = "pending",
  REJECTED = "rejected",
}

export interface Creator {
  id: number;
  bio: string | null;
  profile_image_url: string | null;
  name: string | null;
  stage_name?: string | null;
  informal_name: string | null;
  pronouns: PRONOUNS_Keys | null;
  location: string | null;
  date_of_birth: string | null;
  twitter_handle: string | null;
  wiki_handle: string | null;
  type: string;
  pending_fields?: {
    bio?: string;
    date_of_birth?: string;
    informal_name?: string;
    primary_name?: string;
    stage_name?: string;
    profile_image_url?: string;
    pronouns?: string;
    twitter_handle?: string;
    wiki_handle?: string;
    location?: string;
  };
  pending_fields_status?: {
    bio?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    profile_image_url?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    primary_name?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    informal_name?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    stage_name?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    twitter_handle?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    wiki_handle?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    pronouns?: {
      value: PRONOUNS_Keys;
      status: PENDING_FIELD_STATUS;
    };
    date_of_birth?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
    location?: {
      value: string;
      status: PENDING_FIELD_STATUS;
    };
  };
}

interface User {
  id: number;
  username: string;
  email?: string;
  type: string;
  join_date: string;
  profile_image_url: string;
  likes?: number;
  disLikes?: number;
}

interface CreditSubmission {
  id: number;
  podcast_id: number;
  creator_id: number;
  role_code: string;
  user_id: string | null;
  ongoing?: number;
  episode_ids?: number[];
  operation?: TASK_TYPE;
}

export interface Episode {
  title: string;
  description: string;
  url: string;
  image_url?: string;
  audio_url: string;
  air_date: string;
  id: number;
  length: number;
}

export interface Podcast {
  id: number;
  title: string;
  image_url: string;
  description: string;
  author: string;
  url: string;
  slug: string;
  guestCredits?: number;
  web_url: string;
  recentGuests?: string;
}

interface Role {
  code: string;
  description: string;
  title: string;
}

export enum TASK_DECISION_STATUS {
  FAILURE = "FAILURE",
  WAITING_FOR_CONSENSUS = "WAITING_FOR_CONSENSUS",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
}

export enum TASK_TYPE {
  ADD_ONGOING_CREDITS = "add_ongoing_credit",
  ADD_EPISODE_CREDITS = "add_episode_credits",
  REMOVE_ONGOING_CREDITS = "remove_ongoing_credit",
}

export interface Task {
  id: string;
  type: TASK_TYPE;
  decision_status: TASK_DECISION_STATUS;
  task_info: {
    ongoing?: number;
    podcast_id: number;
    creator_id: number;
    episode_ids?: number[];
    role_code: string;
  };
  required_for_consensus: number;
}

export interface Sequence {
  _id: string;
  id: string;
  type: SequenceType;
  created_at: string;
  updated_at: string;
  locked_at: string;
  locked_by: number;
  can_be_moderated_by: string[];
  skipped_by_users: string[];
  creator: Creator;
  creditSubmission?: CreditSubmission | null;
  creditSubmissionOngoing?: CreditSubmission;
  episodes?: Episode[] | null;
  podcast: Podcast;
  status?: SequenceStatus;
  role: Role;
  submittingUser: User;
  tasks: Task[];
}
